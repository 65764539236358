<template>
  <div
    class="w-full u-copy"
    :class="textAlignment"
    v-html="block.text"
  />
</template>

<script lang="ts" setup>
  import { CopyBlockType } from "@/types/BlockType";
  import { computed } from "vue";

  const props = defineProps<CopyBlockType>();

  const textAlignment = computed(() => `text-${props.block.alignment}`);
</script>