<template>
  <div
    v-if="data"
  >
    <PageBackground :data="data.page.backgroundImage">
      <PageSection
        v-for="section in data.page.content"
        :key="section.id"
        :data="section"
      />
    </PageBackground>
  </div>
</template>

<script lang="ts" setup>
  import { ref, onMounted } from "vue";
  import { useRouter, useRoute } from "vue-router";
  import { request } from "@/lib/datocms";
  import { DynamicQueryData } from "@/types/DatoType";
  import { PageQuery } from "@/queries/PageQuery";

  const data = ref<DynamicQueryData | null>(null);
  const router = useRouter();
  const route = useRoute();

  onMounted(async () => {
    // this could be an issue later when using multiple dynamic ids, commenting out for now
    // const paramsType = typeof(route.params.page);
    // const paramsPage: string = paramsType == "string" ? route.params.page.toString() : route.params.page[0];
    const thisPage:string | string[] = route.name?.toString() || route.params.page;

    // get page data
    data.value = await request({
      query: PageQuery(thisPage),
      variables: {},
      preview: false,
    });
    
    // no page data, send em packing
    if (!data.value?.page) {
      router.replace("404");
    }
    
  });
</script>