<template>
  <div
    v-if="data"
    class="page-column"
  >
    <PageWrapper>
      <router-view class="page-column page-width" />
    </PageWrapper>
  </div>
</template>

<script setup lang="ts">
  import { ref, onMounted } from "vue";
  // import { useHead } from "@vueuse/head";
  import { request } from "@/lib/datocms";
  // import { toHead } from "vue-datocms";
  import { AppQueryData } from "@/types/DatoType";
  import { AppQuery } from "@/queries/AppQuery";
  import PageWrapper from "@/components/layout/PageWrapper.vue";

  // 
  const data = ref<AppQueryData | null>(null);

  // const computedMeta = computed(() => {
  //   if (!data.value) {
  //     return {};
  //   }

  //   return toHead(data.value.profile.seo, data.value.site.favicon);
  // });

  // useHead(computedMeta);
 
  onMounted(async () => {
    data.value = await request({
      query: AppQuery,
      variables: {},
      preview: false,
    });
  });
</script>