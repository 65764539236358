import { createRouter, createWebHistory, Router, RouteRecordRaw } from "vue-router";
import _idVue from "@/views/_id.vue";
import Error from "@/views/Error.vue";

const routeInfos: RouteRecordRaw[] = [
  {
    path: "/", 
    component: _idVue, 
    name: "home",
  },
  {
    path: "/:page",
    component: _idVue,
  },
  { path: "/404", component: Error },
];

const router = createRouter({
  history: createWebHistory(),
  routes: routeInfos,
});

export default router as Router;