<template>
  <component
    :is="tagType"
    class="w-full"
    :class="[textAlign, tagClass]"
  >
    {{ block.text }}
  </component>
</template>

<script lang="ts" setup>
  import { HeaderBlockType } from "@/types/BlockType";
  import { computed } from "vue";

  const props = defineProps<HeaderBlockType>();

  const textAlign = computed(() => `text-${props.block.alignment}`);
  const tagType = computed(() => props.block.size);
  const tagClass = computed(() => {
    const difference = 2;
    const limit = 6 - difference;
    const tagSize = parseInt(tagType.value.charAt(tagType.value.length - 1));
    const mobileTagSize = tagSize > limit ? tagSize + difference : tagSize;

    return "text-h" + (mobileTagSize + 2) + " md:text-" + tagType.value;
  });
</script>