<template>
  <div
    class="flex w-full justify-between page-block-gap"
    :class="[directionCollapseClass, verticalAlignmentClass]"
  >
    <div :class="`w-full sm:${leftWidthClass}`">
      <component
        :is="block.leftColumnContent._modelApiKey"
        :block="block.leftColumnContent"
      />
    </div>
    <div :class="`w-full sm:${rightWidthClass}`">
      <component
        :is="block.rightColumnContent._modelApiKey"
        :block="block.rightColumnContent"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { TwoColumnRecord } from "@/types/DatoType";
  import { computed } from "vue";

  const props = defineProps<{ 
    block: TwoColumnRecord;
  }>();

  const directionCollapseClass = computed(() => {

    // get both direction & collapse
    const ltr = props.block.leftToRight;
    const clps = props.block.collapse;

    // handle all 4 states
    if (ltr && clps) return "flex-col sm:flex-row";
    else if (!ltr && clps) return "flex-col-reverse sm:flex-row sm:flex-row-reverse";
    else if (!ltr && !clps) return "flex-row flex-row-reverse";
    else return "flex-row";
  });

  const leftWidthClass = computed(() => {
    if (props.block.columnRatio === "1:2") return "w-1/3";
    else if (props.block.columnRatio === "2:1") return "w-2/3";
    else return "w-1/2";
  });

  const rightWidthClass = computed(() => {
    if (props.block.columnRatio === "1:2") return "w-2/3";
    else if (props.block.columnRatio === "2:1") return "w-1/3";
    else return "w-1/2";
  });

  const verticalAlignmentClass = computed(() => {
    return props.block.verticalAlignment ? "items-center" : "";
  });
</script>