import { App as Application } from "vue";

// import dynamic type
import { GlobalCMSBlock, CopyBlockType, HeaderBlockType, ImageBlockType, TwoColumnType } from "@/types/BlockType";

// Import individual Vue components
import ImageBlock from "@/components/blocks/ImageBlock.vue";
import HeaderBlock from "@/components/blocks/HeaderBlock.vue";
import CopyBlock from "@/components/blocks/CopyBlock.vue";
import TwoColumnBlock from "@/components/blocks/TwoColumnBlock.vue";

// link dynamic blocks
const globalCMSBlocks: GlobalCMSBlock = {
  "image_block": ImageBlock as unknown as ImageBlockType,
  "header_block": HeaderBlock as unknown as HeaderBlockType,
  "copy_block": CopyBlock as unknown as CopyBlockType,
  "two_column_block": TwoColumnBlock as unknown as TwoColumnType,
};

// add them globally so the app can match strings to components (like nuxt!)
export const GlobalCMSBlockMapPlugin = {
  install: (app: Application) => {
    Object.keys(globalCMSBlocks).forEach(key => {
      app.component(key, globalCMSBlocks[key]);
    });
  },
};