<template>
  <datocms-image
    :data="block.image.responsiveImage"
    class="w-full"
    :style="maxWidthClass"
  />
</template>

<script lang="ts" setup>
  import { ImageBlockType } from "@/types/BlockType";
  import { computed } from "vue";

  const props = defineProps<ImageBlockType>();

  const maxWidthClass = computed(() => {
    return props.block.maxWidth ? `max-width: ${ props.block.maxWidth }px` : "";
  });
</script>