export const AppQuery = `
{
  site: _site {
    favicon: faviconMetaTags {
      ...metaTagsFragment
    }
  }
  profile {
    seo: _seoMetaTags {
      ...metaTagsFragment
    }
    name
    description
    email
  }
  socials: allSocials {
    social
    url
  }
}
fragment metaTagsFragment on Tag {
  attributes
  content
  tag
}
`;