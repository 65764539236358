<template>
  <div class="page-wrapper">
    <div class="layers bg">
      <div class="layer flat-bg-top" />
      <div class="layer foliage-bg" />
    </div>
    <!-- full-width header -->
    <header class="header content-wrapper mg">
      <div class="content-inner">
        <div class="header-inner">
          <div class="logo">
            <img
              src="@/assets/static/images/header-logo-colour.webp"
              alt="Zem publishing logo: a green tree with deep roots and bright, red-and-orange leaves"
              width="100"
              height="100"
            >
          </div>
        </div>
      </div>
    </header>
    <!-- content -->
    <main class="main content-wrapper mg">
      <!-- hero -->
      <div class="content-inner">
        <section class="content-section transparent-bg-gradient desktop clear-bottom">
          <div class="hero-image">
            <div class="image-calendra">
              <img
                src="@/assets/static/images/calendra.webp"
                alt="The book's hero, Calendra, sits cross-legged and meditates on some white flowers. She holds a paintbrush in her left hand, dipped in blue ink."
                width="420"
                height="425"
              >
            </div>
          </div>
        </section>

        <section class="content-section mobile">
          <div class="hero-image">
            <div class="image-calendra">
              <img
                src="@/assets/static/images/calendra.webp"
                alt="The book's hero, Calendra, sits cross-legged and meditates on some white flowers. She holds a paintbrush in her left hand, dipped in blue ink."
                width="420"
                height="425"
              >
            </div>
          </div>
        </section>

        <!-- spacer -->
        <div class="spacer xl transparent-bg-gradient-hero mobile">
          <span>&nbsp;</span>
        </div>

        <section class="content-section transparent-bg mobile">
          <img
            src="@/assets/static/images/foliage-fg.webp"
            class="foliage-fg foliage-mobile-offset"
            alt=""
          >
          <div class="divider-image mb-10">
            <div class="image-title">
              <img
                src="@/assets/static/images/title-mobile-2x.webp"
                alt="Daughter of the Manifold"
                width="589"
                height="320"
              >
            </div>
          </div>
        </section>
        
        <section class="content-section transparent-bg">
          <div class="hero-wrapper">
            <div class="column text text-hero center">
              <div>
                &OpenCurlyDoubleQuote;Calendra finishes meditating and thanks the sprawling banyan. It is time. She ignores the cries of her pursuers. Nobody but a Manifold Artist &ndash; the living legends of bedtime stories &ndash; can hope to catch her. She will soon be something beyond even them.
              </div>
              <div>
                The Daughter of the Manifold has nothing to fear. She has something to pursue. 
              </div>
              <div class="quote text-md">
                Calendra becomes <span class="word">Fast</span>.&CloseCurlyDoubleQuote;
              </div>
            </div>
          </div>
          <div class="hero-title desktop">
            <div class="image-title">
              <img
                src="@/assets/static/images/title.png"
                alt="Daughter of the Manifold"
                width="1072"
                height="100"
              >
            </div>
          </div>
        </section>
        <section class="content-section divider-bg flip">
          <img
            src="@/assets/static/images/foliage-fg.webp"
            class="foliage-fg desktop"
            alt=""
          >
          <div class="divider-image">
            <img
              src="@/assets/static/images/arrow.png"
              alt=""
              role="presentation"
            >
          </div>
        </section>
      </div>
      <!-- the book -->
      <div class="content-inner mt-6 sm:mt-20">
        <section class="content-section transparent-bg">
          <div class="text text-fancy text-heading text-heavy text-mob center">
            ABOUT THE BOOK
          </div>
          <div class="two-column">
            <div class="image-book">
              <img
                src="@/assets/static/images/book-promo.webp"
                alt="A mockup of the book's cover, sitting in a field of grass with trees in the background"
                width="500"
                height="500"
              >
            </div>
            <div class="v-center">
              <div class="text text-base-lg column v-center mt-6 sm:mt-0">
                <div>
                  Civilisations posture for control of the ten magical Arts while dangerous truths about humanity's botanical utopia risk falling into the wrong hands.
                </div>
                <div>
                  When the powerful Announcer learns of Calendra's unique relationship with the reality-bending Arts of the Manifold, she must choose whether to flee to safety or chase the extraordinary.
                </div>
              </div>
            </div>
          </div>

          <div class="text text-highlight center mb-14 sm:mb-0 mt-6 sm:mt-10">
            <div>
              As her vibrant world dims and she discovers a new path to power, it is the war between Calendra's humanity and ambition that will shape the future of magic.
            </div>
          </div>
        </section>

        <section class="content-section content-gap-md transparent-bg mb-16">
          <div class="text text-base column center pb-12 sm:pb-0">
            <div>
              <i>&OpenCurlyDoubleQuote;Daughter of the Manifold&CloseCurlyDoubleQuote;</i> is an introspective tale of power, ambition and solitude, set in a vibrant botanical world teeming with known and unknown magic.
            </div>
            <div>
              It follows the life of an extraordinary young woman with the talent, opportunity and drive to change the world, as she pursues power, freedom, and protection of her jungle home.
            </div>
            <div>
              It is the first in a trilogy, but its own complete story. The tale deals with adult themes but is suitable for middle-grade and above.
            </div>
          </div>
        </section>
      </div>
    </main>

    <!-- cutout divider w/ book links -->
    <main class="main content-wrapper cutout cutout-bg-sky mg inset flower">
      <div class="content-inner cutout pb-12 pt-24 sm:pb-0 sm:pt-12">
        <!-- REMOVE THIS LATER -->
        <section class="content-section">
          <div class="text text-fancy text-xl text-heavy center">
            <div>
              GET YOUR COPY NOW!
            </div>
          </div>

          <div class="two-column buy-icons">
            <div>
              <div class="text text-md text-center text-italic">
                All Formats
              </div>
              <div class="buy-icon">
                <a
                  href="https://mybook.to/manifoldarts"
                  target="_blank"
                >
                  <img
                    src="@/assets/static/images/btn-amazon.webp"
                    alt="amazon.com logo"
                  >
                </a>
              </div>
            </div>
            <div>
              <div class="text text-md text-center text-italic">
                Physical
              </div>
              <div class="buy-icon drop ingram">
                <a
                  href="https://books2read.com/manifold"
                  target="_blank"
                >
                  <img
                    src="@/assets/static/images/spark-logo.webp"
                    alt="ingram spark logo"
                  >
                </a>
              </div>
            </div>
          </div>
        
          <div class="text text-md column center px-16 desktop">
            <div>
              Available in E-Book, <br class="desktop">Paperback, Hardcover,<br class="desktop"> and Kindle Unlimited.
            </div>
          </div>

          <div class="text text-md column center mobile">
            <div>
              Available in E-Book, <br class="desktop">Paperback, Hardcover,<br class="desktop"> and Kindle Unlimited.
            </div>
          </div>
        </section>
      </div>
    </main>
    <main class="main content-wrapper mg mt-0 sm:mt-10">
      <!-- the book -->
      <div class="content-inner">
        <section class="content-section clear-top clear-bottom divider-bg">
          <div class="divider-image-author">
            <img
              src="@/assets/static/images/author-flower.webp"
              alt=""
              role="presentation"
            >
          </div>
        </section>
        
        <section class="content-section clear-top clear-bottom transparent-bg">
          <div class="text text-fancy text-heading text-heading-author text-heavy center">
            ABOUT THE AUTHOR
          </div>

          <div class="two-column author">
            <div class="image-author">
              <img
                src="@/assets/static/images/david.jpg"
                alt="A picture of the author, David Maxwell"
                width="278"
                height="257"
              >
            </div>
            <div class="v-center pt-6 sm:pt-0">
              <div class="text text-base column v-center">
                <div>
                  David Maxwell is an Australian author who writes in his spare time, and loves it.
                </div>
                <div>
                  He also loves his beautiful wife and adorable greyhound. And he loves travel, hiking, scuba diving and all things science. He loves a good answer, but loves a good question more &ndash; particularly the questions <i>what is</i> and <i>what if</i>. The first led him to a love of science, the second led him to a love of fantasy.
                </div>
              </div>
            </div>
          </div>
          
          <div class="text text-base column">
            <div>
              With Rowling and Tolkien as his gateway into fantasy, and Hobb and Feist his next steps in a journey through speculative worlds, it was Brandon Sanderson who showed him what fantasy could be, leading to years of in-depth exploration of hard magic and hard world-building. Writers like Rothfuss, Jemisin and Wight revealed a space of fantasy storytelling broader than he imagined in his early days of swords and sorcery fantasy. 
            </div>
            <div>
              Motivated by the stories, characters, worlds and magics of the expanding fantasy genre, David was prompted to ask &ndash; and answer &ndash; his own <i>what if</i>.
            </div>
          </div>

          <div class="text text-md row icons pt-6 sm:pt-12">
            <a
              href="https://www.facebook.com/profile.php?id=61556485200320&mibextid=ZbWKwL"
              target="_blank"
              class="icon"
            >
              <img
                width="64"
                height="64"
                src="@/assets/static/images/icons/facebook-icon.webp"
                alt="facebook icon"
              >
              <img
                width="64"
                height="64"
                src="@/assets/static/images/icons/facebook-icon-on.webp"
                alt="facebook icon"
              >
            </a>
            <a
              href="https://www.goodreads.com/author/show/21905785.David_Maxwell"
              target="_blank"
              class="icon"
            >
              <img
                width="64"
                height="64"
                src="@/assets/static/images/icons/goodreads-icon.webp"
                alt="goodreads icon"
              >
              <img
                width="64"
                height="64"
                src="@/assets/static/images/icons/goodreads-icon-on.webp"
                alt="goodreads icon"
              >
            </a>
            <a
              href="mailto:author@david-maxwell.com?subject=Website contact"
              target="_blank"
              class="icon"
            >
              <img
                width="64"
                height="64"
                src="@/assets/static/images/icons/mail-icon.webp"
                alt="envelope icon"
              >
              <img
                width="64"
                height="64"
                src="@/assets/static/images/icons/mail-icon-on.webp"
                alt="envelope icon"
              >
            </a>
          </div>
        </section>

        <section class="content-section divider-bg clear-top clear-bottom flip">
          <div class="divider-image-flower">
            <img
              src="@/assets/static/images/cutout-flower.webp"
              alt=""
              role="presentation"
            >
          </div>
        </section>
      </div>
    </main>

    <!-- cutout divider w/ book links -->
    <main class="main content-wrapper cutout cutout-bg-newsletter mg inset mt-0 sm:mt-12 mb-24">
      <div class="content-inner py-12 sm:py-0">
        <section class="content-section content-gap-none">
          <div class="text text-fancy text-xl text-heavy center pb-[20px]">
            <div>
              KEEP UP TO DATE
            </div>
          </div>

          <div class="text text-md column center max-w-[600px]">
            <div class="text-italic">
              Join David Maxwell's newsletter for free short stories and updates on the next novel.
            </div>
          </div>
          <!-- mailer component -->
          <MailerLiteEmbed />
        </section>
      </div>
    </main>

    <footer class="mg">
      <div class="footer-text">
        <div>&copy; Zem Publishing 2024 ABN 93 503 294 234. All rights reserved.</div>
        <div class="footer-center">
          Cover art by <a href="https://www.instagram.com/elisgardor/?hl=en">@elisgardor</a>
        </div>
        <div>Designed by <a href="https://aao.design/">AAO design</a>.</div>
      </div>
    </footer>
  </div>
</template>