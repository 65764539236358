import "@/assets/scss/index.scss";
import "@/assets/scss/onepager.scss";
import { createApp } from "vue";
import router from "@/router/router";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faBriefcase, faGlobe, faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import { DatocmsImagePlugin } from "vue-datocms";
import { GlobalCMSBlockMapPlugin } from "@/plugins/GlobalCMSBlockMap";

import {
  faFacebook,
  faTwitter,
  faGithub,
  faDribbble,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import { createHead } from "@vueuse/head";
import App from "@/App.vue";

/* eslint-disable @stylistic/padding-line-between-statements */
library.add(faBriefcase);
library.add(faMoon);
library.add(faSun);
library.add(faTwitter);
library.add(faGithub);
library.add(faDribbble);
library.add(faInstagram);
library.add(faYoutube);
library.add(faGlobe);
library.add(faFacebook);

const app = createApp(App);
const head = createHead();

app.use(head);
app.use(router);
app.use(DatocmsImagePlugin);
// app.use(DatocmsStructuredTextPlugin);
app.use(GlobalCMSBlockMapPlugin);
app.component("FontAwesomeIcon", FontAwesomeIcon);
app.mount("#app");
/* eslint-enable @stylistic/padding-line-between-statements */
