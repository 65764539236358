export const PageQuery = (pageId: string | string[]) => `
{
  page(filter: {slug: {eq: "${pageId}"}}){
    id
    slug
    title
    content {
      id
      title
      content {
        ... pageSectionBlocks
      }
    }
    backgroundImage {
      responsiveImage {
        ... imageFields
      }
    }
  }
}

fragment pageSectionBlocks on RecordInterface {
  ... on RecordInterface {
    ... recordFields
  }
  ... on ImageBlockRecord {
    ... imageBlockFields
  }
  ... on HeaderBlockRecord {
    ... headerBlockFields
  }
  ... on CopyBlockRecord {
    ... copyBlockFields
  }
  ... on TwoColumnBlockRecord {
    ... twoColumnBlockFields
  }
}

fragment twoColumnBlocks on RecordInterface {
  ... on RecordInterface {
    ... recordFields
  }
  ... on ImageBlockRecord {
    ... imageBlockFields
  } 
  ... on CopyBlockRecord {
    ... copyBlockFields
  }
}

fragment imageFields on ResponsiveImage {
  srcSet
  sizes
  src
  width
  height
  alt
  title
  base64
}

fragment recordFields on RecordInterface {
  id
  _modelApiKey
}

fragment imageBlockFields on ImageBlockRecord {
  title
  linkUrl
  image {
    responsiveImage {
      ... imageFields
    }
  }
  maxWidth
}

fragment headerBlockFields on HeaderBlockRecord {
  text
  alignment
  size
}

fragment copyBlockFields on CopyBlockRecord {
  text(markdown: false)
  alignment
}

fragment twoColumnBlockFields on TwoColumnBlockRecord {
  id
  collapse
  columnRatio
  leftToRight
  verticalAlignment
  leftColumnContent {
    ... twoColumnBlocks
  }
  rightColumnContent {
    ... twoColumnBlocks
  }
}
`;